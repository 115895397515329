<template>
  <div>
    <v-card>
      <v-card-title> Login </v-card-title>
      <v-card-text>
        <v-text-field type="text" label="E-Mail" v-model="email"></v-text-field>
      </v-card-text>
      <v-card-text>
        <v-text-field
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="showPassword = !showPassword"
          @keydown.enter.stop="handleLogin"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleLogin">Login</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    email: "",
    password: "",
    showPassword: false
  }),
  methods: {
    ...mapActions(["login"]),
    handleLogin() {
      this.login({
        email: this.email,
        password: this.password
      }).then(response => {
        if (response.status == "ok") this.$router.push("/admin");
      });
    }
  }
};
</script>
