var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.pubs,"dense":"","search":_vm.pubHeaderData.search,"hide-default-footer":"","sort-by":"pubDate","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
return [_c('pub-header',{attrs:{"opts":_vm.pubHeaderData,"pagination":pagination},on:{"prev-page":function($event){options.page--},"next-page":function($event){options.page++}}})]}},{key:"item.pubDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pubDate.substring(0, 4))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type.toUpperCase())+" ")]}},{key:"item.available",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.available ? 'green' : 'red',"small":""}},[_vm._v(" mdi-circle ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }