<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="biblio"
      show-expand
      :expanded.sync="expanded"
      single-expand
      :search="pubHeaderData.search"
      hide-default-footer
    >
      <template #top="{ pagination, options }">
        <pub-header
          :opts="pubHeaderData"
          :pagination="pagination"
          @prev-page="options.page--"
          @next-page="options.page++"
        ></pub-header>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <biblio-pub-table
            :headers="pubHeaders"
            :items="item.pubs"
          ></biblio-pub-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import BiblioPubTable from "../components/BiblioPubTable.vue";
  import PubHeader from "../components/PubHeader.vue";
  export default {
    components: { BiblioPubTable, PubHeader },
    data: () => ({
      pubHeaderData: {
        search: "",
        pagination: {},
      },
      expanded: [],
      pubHeaders: [
        {
          text: "Publication",
          value: "publication",
        },
        {
          text: "Date",
          value: "pubDate",
        },
        {
          text: "Publisher",
          value: "publisher",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Available",
          value: "available",
        },
        {
          text: "Note",
          value: "note",
        },
      ],
      headers: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Type",
          value: "type",
        },
      ],
    }),
    computed: {
      ...mapState(["biblio"]),
    },
  };
</script>
