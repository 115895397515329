<template>
  <v-app>
    <v-app-bar app src="@/assets/banner-background.webp" height="250" dark>
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        style="position: absolute; top: 0"
        @click="showSideBar = true"
      ></v-app-bar-nav-icon>

      <header-contents></header-contents>
      <image-attribution></image-attribution>
      <template v-slot:extension v-if="$vuetify.breakpoint.smAndUp">
        <nav-tabs :tabs="tabList"></nav-tabs>
      </template>
    </v-app-bar>

    <v-navigation-drawer app v-model="showSideBar" color="#c4ac75">
      <v-toolbar flat color="#c4ac75">
        <v-spacer></v-spacer>
        <v-btn icon @click="showSideBar = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-toolbar>

      <v-tabs vertical grow background-color="#c4ac75" color="black">
        <v-tab
          v-for="(tab, index) in tabList"
          :key="`nav-tab-${index}`"
          :to="tab.path"
        >
          {{ tab.name }}
        </v-tab>
      </v-tabs>
    </v-navigation-drawer>

    <v-main class="background-gradient">
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import HeaderContents from "@/components/HeaderContents";
  import ImageAttribution from "@/components/ImageAttribution";
  import NavTabs from "@/components/NavTabs";

  export default {
    components: { HeaderContents, ImageAttribution, NavTabs },

    name: "App",

    data: () => ({
      showSideBar: false,
      tabList: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "About",
          path: "/about",
        },
        {
          name: "Biblio",
          path: "/biblio",
        },
        {
          name: "Pubs",
          path: "/pubs",
        },
      ],
    }),

    created() {
      this.$store.dispatch("getSiteData");
    },
  };
</script>

<style>
  .header-highlight {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-shadow: 0px 0px 4px #000000;
  }

  .header-title {
    bottom: 100px;
  }
</style>
