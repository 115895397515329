<template>
  <div>
    <pubs
      :items="upcoming"
      title="Coming Soon"
      showPubDate="true"
      pubDateTitle="expected publication date"
      v-if="upcoming.length > 0"
    ></pubs>
    <pubs :items="selectedPubs" title="selected titles"></pubs>
  </div>
</template>

<script>
  import Pubs from "@/components/Pubs";
  import { mapState } from "vuex";
  export default {
    components: {
      Pubs,
    },
    computed: {
      ...mapState(["selectedPubs", "upcoming"]),
    },
  };
</script>
