<template>
  <div>
    <v-container>
      <v-data-table :headers="headers" :items="items" hide-default-footer>
        <template #[`item.available`]="{ item }">
          <v-icon :color="item.available ? 'green' : 'red'" small>
            mdi-circle
          </v-icon>
        </template>

        <template #[`item.note`]="{ item }">
          <v-menu offset-y>
            <template #activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon> mdi-note </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text v-html="markdown(item.note)"> </v-card-text>
            </v-card>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
  import { marked } from "marked";
  export default {
    props: ["headers", "items"],
    methods: {
      markdown(str) {
        return marked(str);
      },
    },
  };
</script>