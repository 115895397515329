<template>
  <div>
    <v-card flat>
      <v-container>
        <v-row>
          <v-col cols="2" v-if="$vuetify.breakpoint.smAndUp">
            <v-img :src="`/api/cover/${pub.cover}`" contain></v-img>
          </v-col>
          <v-col>
            <v-card-title>
              {{ pub.publication }}
            </v-card-title>
            <v-card-subtitle>
              {{ pubDate }}
            </v-card-subtitle>
            <v-card-text>
              <div>Publisher: {{ pub.publisher }}</div>
              <div>
                {{ pubType }}
              </div>
            </v-card-text>
            <v-card-text v-html="pubNote" class="pub-note"></v-card-text>
            <v-card-actions>
              <div class="mr-12">Available as:</div>
              <template v-for="(option, i) in pub.purchase">
                <v-btn
                  :key="`purchase-option-${i}`"
                  class="mr-12"
                  :href="option.url"
                  target="_blank"
                >
                  {{ option.type }}
                </v-btn>
              </template>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
  import { format } from "date-fns";
  import { startCase } from "lodash";
  import { marked } from "marked";
  export default {
    props: ["pub"],
    computed: {
      pubDate() {
        return format(new Date(this.pub.pubDate.split("-")), "MMMM d, yyyy");
      },
      pubType() {
        return startCase(this.pub.type);
      },
      pubNote() {
        return marked.parse(this.pub.note);
      },
    },
  };
</script>

<style>
  .pub-note {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
</style>
