<template>
  <v-tabs centered color="black" background-color="#C4AC75">
    <v-tab
      v-for="(tab, index) in tabs"
      :key="`nav-tab-${index}`"
      :to="tab.path"
    >
      {{ tab.name }}
    </v-tab>
  </v-tabs>
</template>

<script>
  export default {
    props: ["tabs"],
  };
</script>
