<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="pubs"
      dense
      :search="pubHeaderData.search"
      hide-default-footer
      sort-by="pubDate"
      sort-desc
    >
      <template #top="{ pagination, options }">
        <pub-header
          :opts="pubHeaderData"
          :pagination="pagination"
          @prev-page="options.page--"
          @next-page="options.page++"
        ></pub-header>
      </template>

      <template #[`item.pubDate`]="{ item }">
        {{ item.pubDate.substring(0, 4) }}
      </template>

      <template #[`item.type`]="{ item }">
        {{ item.type.toUpperCase() }}
      </template>

      <template #[`item.available`]="{ item }">
        <v-icon :color="item.available ? 'green' : 'red'" small>
          mdi-circle
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import PubHeader from "@/components/PubHeader";

  import { mapState } from "vuex";
  export default {
    components: { PubHeader },

    data: () => ({
      pubHeaderData: { search: "" },
      headers: [
        {
          text: "Title",
          value: "publication",
        },
        {
          text: "Published",
          value: "pubDate",
        },
        {
          text: "Genre",
          value: "type",
        },
        {
          text: "Available",
          value: "available",
        },
      ],
    }),
    computed: {
      ...mapState(["pubs"]),
    },
  };
</script>
