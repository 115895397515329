<template>
  <div>
    <about-card
      v-for="{ section, content } in about"
      :key="`about-section-${section}`"
      :section="section"
      :content="content"
      class="mb-2"
    >
    </about-card>
  </div>
</template>

<script>
import AboutCard from "@/components/AboutCard";
import { mapState } from "vuex";
export default {
  components: {
    AboutCard
  },
  computed: {
    ...mapState(["about"])
  }
};
</script>
