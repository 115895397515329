<template>
  <div>
    <v-toolbar flat>
      <v-text-field
        v-model="opts.search"
        label="Search"
        clearable
        clear-icon="mdi-close"
        @keydown.esc="opts.search = ''"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('prev-page')" :disabled="pagination.page == 1">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>

      Page {{ pagination.page }} of {{ pagination.pageCount }}

      <v-btn
        icon
        @click="$emit('next-page')"
        :disabled="pagination.page == pagination.pageCount"
      >
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
  export default {
    props: ["opts", "pagination"],
  };
</script>