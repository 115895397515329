import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "@/views/About";
import Biblio from "@/views/Biblio";
import Publications from "@/views/Publications";
import Admin from "@/views/Admin";
import Login from "@/views/Login";

Vue.use(VueRouter);

import wretch from "wretch";

const token = sessionStorage.getItem("admin-token");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/biblio",
    alias: "/bibliography",
    name: "Bibliography",
    component: Biblio
  },
  {
    path: "/pubs",
    alias: "/publications",
    name: "Publications",
    component: Publications
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (token) {
      wretch("/api/admin/verify-token")
        .formData({ token })
        .post()
        .json()
        .then(response => {
          if (response.status == "ok") {
            next();
          } else {
            next("/login");
          }
        });
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
