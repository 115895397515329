<template>
  <div class="white--text display-2 text-center header-highlight header-title">
    <span v-if="$vuetify.breakpoint.smAndUp">Mike Casto&mdash;Author</span>
    <div v-else>
      <div>Mike Casto</div>
      <div>Author</div>
    </div>
  </div>
</template>

<script>
  export default {};
</script>
