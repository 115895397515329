<template>
  <div>
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text v-html="text"></v-card-text>
    </v-card>
  </div>
</template>

<script>
import { marked } from "marked";
export default {
  props: ["section", "content"],
  computed: {
    title() {
      return this.section.toUpperCase();
    },
    text() {
      return marked.parse(this.content);
    }
  }
};
</script>
