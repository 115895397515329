<template>
  <div>
    <v-card class="my-3">
      <v-card-title>
        {{ displayTitle }}
      </v-card-title>
      <div v-for="pub in items" :key="pub.id">
        <pub-card :pub="pub"></pub-card>
      </div>
    </v-card>
  </div>
</template>

<script>
import { startCase } from "lodash";
import PubCard from "@/components/PubCard";
export default {
  components: { PubCard },
  props: ["items", "title", "showPubDate", "pubDateTitle"],
  computed: {
    displayTitle() {
      return startCase(this.title);
    },
    displayPubDateTitle() {
      return startCase(this.pubDateTitle);
    }
  }
};
</script>
