import { startCase } from "lodash";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import wretch from "wretch";
import { decompress } from "../assets/gz";

export default new Vuex.Store({
  state: {
    biblio: [],
    pubs: [],
    selectedPubs: [],
    upcoming: [],
    about: [],
    freebies: [],
    token: sessionStorage.getItem("admin-token")
  },
  mutations: {},
  actions: {
    getSiteData({ state }) {
      wretch("/api/get-site-data")
        .get()
        .text()
        .then(decompress)
        .then(JSON.parse)
        .then(data => {
          state.about = data.about;

          state.pubs = data.pubs.sort((a, b) =>
            a.pubDate < b.pubDate ? 1 : -1
          );

          state.biblio = data.biblio.map(item => {
            return {
              ...item,
              type: startCase(item.type),
              pubs: data.pubs
                .filter(({ id }) => item.pubs.includes(id))
                .map(item => {
                  return { ...item, type: startCase(item.type) };
                })
            };
          });

          state.upcoming = data.upcoming.sort((a, b) =>
            a.pubDate > b.pubDate ? 1 : -1
          );

          state.selectedPubs = data.selected.sort((a, b) =>
            a.pubDate > b.pubDate ? -1 : 1
          );
        });
    },
    login({ state }, { email, password }) {
      return wretch("/api/admin/login")
        .formData({ email, password })
        .post()
        .json()
        .then(response => {
          if (response.status == "ok") {
            sessionStorage.setItem("admin-token", response.message);
            state.token = response.message;
          }

          return response;
        });
    }
  },
  modules: {}
});
